import {useEffect, useLayoutEffect, useRef, useState} from 'react';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {BLOB, Person} from '@refinio/one.core/lib/recipes.js';
import {createFileWriteStream} from '@refinio/one.core/lib/system/storage-streams.js';
import SomeoneModel from '@refinio/one.models/lib/models/Leute/SomeoneModel.js';
import type {Someone} from '@refinio/one.models/lib/recipes/Leute/Someone.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import {getInstanceOwnerIdHash} from '@refinio/one.core/lib/instance.js';
import {isString} from '@refinio/one.core/lib/util/type-checks-basic.js';
import {createAccess} from '@refinio/one.core/lib/access';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common';

import {FILE_FORMATS} from '@/components/Constants.js';

/**
 * Check if an standalone application is used
 */
export function isStandalone(): boolean {
    // ts-ignore needed because window.navigator.standalone is available only for safari
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isInStandalone = window.navigator.standalone;

    return !!isInStandalone;
}

/**
 * Returns the URL of the image
 * @param arrayBuffer
 * @returns - the URL
 */
export function getURL(arrayBuffer: ArrayBuffer | File | string): string {
    const blob = new Blob([arrayBuffer]);
    return URL.createObjectURL(blob);
}

/**
 * Used to scroll to the bottom of an html element.
 * @param element - an HTMLElement.
 */
export function scrollToBottom<T extends HTMLElement>(element: T | null): void {
    if (element !== null) {
        window.scrollTo({
            top: element.clientHeight,
            behavior: 'auto'
        });
    }
}

/**
 * Custom hook for getting the previous value of a state.
 * @param value - the value of a state.
 */
export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

/**
 * Get the input accepted file types.
 */
export function getInputAcceptedTypes(array: string[]): string {
    let accept = '';
    array.forEach(type => {
        accept += '.' + type + ',';
    });
    accept = accept.slice(0, -1);
    return accept;
}

/**
 * The regex will extract the name and the extension from fileName.
 * @param fileName
 */
export function getFileExtension(fileName: string): string | undefined {
    return fileName.split(/\.(?=[^.]+$)/)[1];
}

/**
 * Used to change the background color to a custom color.
 */
function changeBackgroundColor(className: string): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.add(className);
    }
}

/**
 * Used to remove the custom background.
 */
function removeBackgroundColor(className: string): void {
    const rootHTMLElement = document.getElementById('root');

    if (rootHTMLElement) {
        rootHTMLElement.classList.remove(className);
    }
}

/**
 * Used to change the background color of a component.
 * @param backgroundColor
 */
export function useBackgroundColor(backgroundColor: string): void {
    useEffect(() => {
        changeBackgroundColor(backgroundColor);

        return () => removeBackgroundColor(backgroundColor);
    }, [backgroundColor]);
}

/**
 * Saving the image in ONE as a BLOB and returning the reference for it.
 *
 * @param image - the image that is saved in ONE as a BLOB.
 * @returns - The reference to the saved BLOB.
 */
export async function saveImageAsBLOB(image: ArrayBuffer): Promise<SHA256Hash<BLOB>> {
    const stream = createFileWriteStream();
    stream.write(image);

    const blob = await stream.end();

    return blob.hash;
}

/**
 * Used to catch the errors when an image is uploaded.
 * @param uploadedFile
 */
export function getUploadImageError(uploadedFile: File): string {
    const uploadedFileExtension = getFileExtension(uploadedFile.name)?.toLowerCase();

    // if the file doesn't have an extension, display the error
    if (uploadedFileExtension === undefined) {
        return 'common.errors.fileUpload.corruptedFile';
    }

    // if the file extension doesn't exist in the accepted file format array then display the error
    if (FILE_FORMATS.find(element => element === uploadedFileExtension) === undefined) {
        return 'common.errors.fileUpload.pictureFormatNotAccepted';
    }

    return '';
}

/**
 * Custom hook for getting the size of the window.
 * @returns {number[]} - width and height of the window.
 */
export function useWindowSize(): number[] {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize(): void {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/**
 *
 * @param {Blob} data
 * @param {string} filename
 * @param {string} type
 */
export function download(data: ArrayBuffer, filename: string, type: string): void {
    const file = new Blob([data], {type: type});
    downloadBlob(file, filename);
}

/**
 * @param {Blob} file
 * @param {string} filename
 */
export function downloadBlob(file: Blob, filename: string) {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

/**
 * Creates a new profile for someone
 *
 * @param leuteModel
 * @param someoneId
 * @param personId Optional. if undefined also genarates new main identity for profile
 * @returns
 */
export async function createProfile(
    leuteModel: LeuteModel,
    someoneId: SHA256IdHash<Someone>,
    personId?: SHA256IdHash<Person>
): Promise<ProfileModel> {
    const someoneModel = await SomeoneModel.constructFromLatestVersion(someoneId);
    if (personId) {
        return await leuteModel.createProfileForPerson(personId, undefined, someoneId);
    } else {
        return await leuteModel.createShallowIdentityForSomeone(someoneModel.idHash);
    }
}

/**
 * Creates new someone with a profile name
 *
 * @param leuteModel
 * @param name
 * @returns
 */
export async function createSomeoneWithDescription(
    leuteModel: LeuteModel,
    name: string,
    email?: string,
    imageHash?: SHA256Hash<BLOB>
): Promise<SHA256IdHash<Person>> {
    const someoneId = await leuteModel.createSomeoneWithShallowIdentity(email);
    const someone = await SomeoneModel.constructFromLatestVersion(someoneId);
    const profile = await someone.mainProfile();
    profile.personDescriptions.push({$type$: 'PersonName', name: name});
    if (imageHash) {
        profile.personDescriptions.push({
            $type$: 'ProfileImage',
            image: imageHash
        });
    }
    if (email) {
        profile.communicationEndpoints.push({$type$: 'Email', email: email});
    }
    await profile.saveAndLoad();
    return await someone.mainIdentity();
}

/**
 * @param base64String
 * @returns
 */
export function base64ToArrayBuffer(base64String: string): ArrayBuffer {
    const binary_string = window.atob(base64String);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export async function getBLOBHashFromBase64(base64String: string): Promise<SHA256Hash<BLOB>> {
    return await saveImageAsBLOB(base64ToArrayBuffer(base64String));
}

export async function getDefaultProfile(
    leuteModel: LeuteModel,
    personId: SHA256IdHash<Person>
): Promise<ProfileModel | undefined> {
    const someone = await leuteModel.getSomeone(personId);
    if (!someone) {
        const mePersonId = getInstanceOwnerIdHash();
        if (!mePersonId) {
            throw Error(`Can not extract default profile from personId ${personId}`);
        }
        let defaultProfile: ProfileModel | undefined;
        try {
            defaultProfile = await ProfileModel.constructFromLatestVersionByIdFields(
                personId,
                personId,
                'default'
            );
        } catch (_e) {
            try {
                defaultProfile = await ProfileModel.constructFromLatestVersionByIdFields(
                    personId,
                    mePersonId,
                    'default'
                );
            } catch (_e2) {
                return undefined;
            }
        }
        return defaultProfile;
    }
    const identityProfiles = someone.profilesLazyLoad(personId);
    await Promise.all(identityProfiles.map(async p => await p.loadLatestVersion()));
    return identityProfiles.filter(p => p.profileId === 'default')[0];
}

export async function getDefaultProfilesOfAllIdentities(
    leuteModel: LeuteModel,
    onError?: (error: Error) => void
): Promise<ProfileModel[]> {
    const others = await leuteModel.others();
    const me = await leuteModel.me();
    const all = [...others, me];
    const defaultProfiles: ProfileModel[] = [];

    for (const someone of all) {
        for (const identity of someone.identities()) {
            const defaultProfile = await getDefaultProfile(leuteModel, identity);
            if (defaultProfile) {
                defaultProfiles.push(defaultProfile);
            } else if (onError !== undefined) {
                onError(new Error(`Could not extract default profile from identity ${identity}`));
            } else {
                throw Error(`Could not extract default profile from identity ${identity}`);
            }
        }
    }

    return defaultProfiles;
}

/**
 *
 * @param data
 * @returns
 */
export function readBlobAsText(data: Blob): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();

        fr.addEventListener('load', () => {
            if (isString(fr.result)) {
                resolve(fr.result);
            } else {
                reject(new Error('result is not a string: ' + JSON.stringify(fr.result)));
            }
        });

        fr.addEventListener('error', err => {
            reject(err);
        });

        fr.readAsText(data);
    });
}

/**
 *
 * @param check
 * @param valueTypeCheck callback for asserting if value of array is of certain type
 * @returns
 */
export function isTypeArray(check: unknown, valueTypeCheck: (value: unknown) => boolean): boolean {
    return Array.isArray(check) && check.every(value => valueTypeCheck(value));
}

/**
 * @param personId
 * @param hash
 */
export async function shareHash(
    personIds: SHA256IdHash<Person> | SHA256IdHash<Person>[],
    hash: SHA256Hash
): Promise<void> {
    await createAccess([
        {
            object: hash,
            person: typeof personIds !== 'string' ? personIds : [personIds],
            group: [],
            mode: SET_ACCESS_MODE.ADD
        }
    ]);
}

/**
 * @param personId
 * @param idHash
 */
export async function shareIdHash(
    personIds: SHA256IdHash<Person> | SHA256IdHash<Person>[],
    idHash: SHA256IdHash
): Promise<void> {
    await createAccess([
        {
            id: idHash,
            person: typeof personIds !== 'string' ? personIds : [personIds],
            group: [],
            mode: SET_ACCESS_MODE.ADD
        }
    ]);
}
