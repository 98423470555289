import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';

import PersonAddIcon from '@mui/icons-material/PersonAdd.js';
import GroupAddIcon from '@mui/icons-material/GroupAdd.js';
import GroupIcon from '@mui/icons-material/Group.js';
import EditIcon from '@mui/icons-material/Edit.js';
import ViewIcon from '@mui/icons-material/SourceOutlined.js';

import i18n from '@/i18n.js';

export const MENU_ENTRY = {
    ADD_SOMEONE: 'add-someone',
    ADD_PROFILE: 'add-profile',
    CHANGE_MAIN_PROFILE: 'change-main-profile',
    ADD_GROUP: 'add-group',
    EDIT_GROUP_MEMBERS: 'edit-group-members',
    EDIT_GROUP_DETAILS: 'edit-group-details',
    ADD_ADDRESS: 'add-address',
    ADD_PHONE: 'add-phone',
    ADD_EMAIL: 'add-email',
    EDIT_SOMEONE_PROFILE: 'edit-someone-profile',
    SELECT_IMAGES: 'select-images',
    TAKE_IMAGE: 'take-image',
    SELECT_FILES: 'select-files',
    TOPIC_INFORMATION: 'topic-information',
    OBJECT_ACCESS: 'object-access',
    TAKE_IMAGE_FACE_DETECTOR: 'take-image-face-detector',
    SELECT_IMAGES_FACE_DETECTOR: 'select-images-face-detector',
    VIEW_OWN_SIGN_KEYS: 'view-own-sign-keys',
    ExampleQuestionnaire: 'example-questionnaire',
    OnboardingPatientQuestionnaire: 'onboarding-patient',
    GeneralQuestionnaireShort: 'general-questionnaire-short',
    WhpQuestionnaire: 'whp-questionnaire',
    LFTStudyQuestionnaire: 'lft-study-questionnaire',
    CervicalCancerScreeningAndTreatmentRegisterQuestionnaire:
        'cervical-cancer-screening-and-treatment-register',
    ResclueQuestionnaire: 'resclue',
    IFCPC_colposcopic_terminology_cervix: 'ifcpc_questionnaire',
    ColposcopyExaminationRecord: 'colposcopy_exam_record',
    QuestionnaireEQ5D3L: 'questionnaireEQ5D3L',
    GeneralFeedbackQuestionnaire: 'general_feedback_edda',
    SpecificFeedbackQuestionnaire: 'specific_feedback_edda',
    WBC: 'WBC',
    BodyTemperature: 'BodyTemperature'
} as const;

/**
 * Map MENU_ENTRY entries to content entries, if needed
 * @param entries
 * @returns
 */
export function getPopupMenuContent(entries: PopupMenuEntry[]): PopupMenuEntry[] {
    return entries.map<PopupMenuEntry>((entry: PopupMenuEntry) => {
        if (typeof entry === 'string') {
            switch (entry) {
                case MENU_ENTRY.VIEW_OWN_SIGN_KEYS:
                    return {
                        entry: MENU_ENTRY.VIEW_OWN_SIGN_KEYS,
                        content: (
                            <>
                                <ViewIcon className="cnt-add-option" />
                                {i18n.t('leute.signKey.title')}
                            </>
                        )
                    };
                case MENU_ENTRY.ADD_SOMEONE:
                    return {
                        entry: MENU_ENTRY.ADD_SOMEONE,
                        content: (
                            <>
                                <PersonAddIcon className="cnt-add-option" />
                                {i18n.t('leute.contextMenu.addProfile')}
                            </>
                        )
                    };
                case MENU_ENTRY.ADD_PROFILE:
                    return {
                        entry: MENU_ENTRY.ADD_PROFILE,
                        content: (
                            <>
                                <PersonAddIcon className="cnt-add-option" />
                                {i18n.t('leute.contextMenu.addProfile')}
                            </>
                        )
                    };
                case MENU_ENTRY.CHANGE_MAIN_PROFILE:
                    return {
                        entry: MENU_ENTRY.CHANGE_MAIN_PROFILE,
                        content: (
                            <>
                                <EditIcon className="cnt-add-option" />
                                {i18n.t('leute.contextMenu.changeMainProfile')}
                            </>
                        )
                    };
                case MENU_ENTRY.ADD_GROUP:
                    return {
                        entry: MENU_ENTRY.ADD_GROUP,
                        content: (
                            <>
                                <GroupAddIcon className="cnt-add-option" />{' '}
                                {i18n.t('leute.contextMenu.addGroup')}
                            </>
                        )
                    };
                case MENU_ENTRY.EDIT_GROUP_MEMBERS:
                    return {
                        entry: MENU_ENTRY.EDIT_GROUP_MEMBERS,
                        content: (
                            <>
                                <GroupIcon className="cnt-add-option" />{' '}
                                {i18n.t('leute.contextMenu.editMembers')}
                            </>
                        )
                    };
                case MENU_ENTRY.EDIT_GROUP_DETAILS:
                    return {
                        entry: MENU_ENTRY.EDIT_GROUP_DETAILS,
                        content: (
                            <>
                                <EditIcon className="cnt-add-option" />{' '}
                                {i18n.t('leute.contextMenu.edit')}
                            </>
                        )
                    };
                case MENU_ENTRY.ADD_ADDRESS:
                    return {
                        entry: MENU_ENTRY.ADD_ADDRESS,
                        content: <>{i18n.t('leute.profile.communicationEndpoints.address.title')}</>
                    };
                case MENU_ENTRY.ADD_PHONE:
                    return {
                        entry: MENU_ENTRY.ADD_PHONE,
                        content: <>{i18n.t('leute.profile.communicationEndpoints.phoneNumber')}</>
                    };
                case MENU_ENTRY.ADD_EMAIL:
                    return {
                        entry: MENU_ENTRY.ADD_EMAIL,
                        content: <>{i18n.t('leute.profile.communicationEndpoints.email.title')}</>
                    };
                case MENU_ENTRY.EDIT_SOMEONE_PROFILE:
                    return {
                        entry: MENU_ENTRY.EDIT_SOMEONE_PROFILE,
                        content: (
                            <>
                                <EditIcon className="cnt-add-option" />{' '}
                                {i18n.t('leute.contextMenu.edit')}
                            </>
                        )
                    };
                case MENU_ENTRY.SELECT_IMAGES:
                    return {
                        entry: MENU_ENTRY.SELECT_IMAGES,
                        content: <>{i18n.t('buttons.camera.imageFromGallery')}</>
                    };
                case MENU_ENTRY.TAKE_IMAGE:
                    return {
                        entry: MENU_ENTRY.TAKE_IMAGE,
                        content: <>{i18n.t('buttons.camera.imageFromCamera')}</>
                    };
                case MENU_ENTRY.SELECT_FILES:
                    return {
                        entry: MENU_ENTRY.SELECT_FILES,
                        content: <>{i18n.t('buttons.device.fileFromDevice')}</>
                    };
                case MENU_ENTRY.TOPIC_INFORMATION:
                    return {
                        entry: MENU_ENTRY.TOPIC_INFORMATION,
                        content: <>{i18n.t('chat.menu.topicInformation')}</>
                    };
                case MENU_ENTRY.OBJECT_ACCESS:
                    return {
                        entry: MENU_ENTRY.OBJECT_ACCESS,
                        content: <>{i18n.t('journal.entryMenu.sharing')}</>
                    };
                case MENU_ENTRY.TAKE_IMAGE_FACE_DETECTOR:
                    return {
                        entry: MENU_ENTRY.TAKE_IMAGE_FACE_DETECTOR,
                        // TODO: Add Translation
                        content: <>Take Image Detect Face</>
                    };
                case MENU_ENTRY.SELECT_IMAGES_FACE_DETECTOR:
                    return {
                        entry: MENU_ENTRY.SELECT_IMAGES_FACE_DETECTOR,
                        // TODO: Add Translation
                        content: <>Select Images Detect Face</>
                    };
                case MENU_ENTRY.ExampleQuestionnaire:
                    return {
                        entry: MENU_ENTRY.ExampleQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.example')}</>
                    };
                case MENU_ENTRY.OnboardingPatientQuestionnaire:
                    return {
                        entry: MENU_ENTRY.OnboardingPatientQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.onBoarding')}</>
                    };
                case MENU_ENTRY.GeneralQuestionnaireShort:
                    return {
                        entry: MENU_ENTRY.GeneralQuestionnaireShort,
                        content: <>{i18n.t('menu.questionnaires.generalQuestionnaireShort')}</>
                    };
                case MENU_ENTRY.WhpQuestionnaire:
                    return {
                        entry: MENU_ENTRY.WhpQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.whpQuestionnaire')}</>
                    };
                case MENU_ENTRY.LFTStudyQuestionnaire:
                    return {
                        entry: MENU_ENTRY.LFTStudyQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.LFTStudyQuestionnaire')}</>
                    };
                case MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire:
                    return {
                        entry: MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire,
                        content: (
                            <>
                                {i18n.t(
                                    'menu.questionnaires.CervicalCancerScreeningAndTreatmentRegister'
                                )}
                            </>
                        )
                    };
                case MENU_ENTRY.IFCPC_colposcopic_terminology_cervix:
                    return {
                        entry: MENU_ENTRY.IFCPC_colposcopic_terminology_cervix,
                        content: <>{i18n.t('menu.questionnaires.ifcpc')}</>
                    };
                case MENU_ENTRY.ResclueQuestionnaire:
                    return {
                        entry: MENU_ENTRY.ResclueQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.resclue')}</>
                    };
                case MENU_ENTRY.ColposcopyExaminationRecord:
                    return {
                        entry: MENU_ENTRY.ColposcopyExaminationRecord,
                        content: <>{i18n.t('menu.questionnaires.colposcopy_exam_record')}</>
                    };
                case MENU_ENTRY.QuestionnaireEQ5D3L:
                    return {
                        entry: MENU_ENTRY.QuestionnaireEQ5D3L,
                        content: <>{i18n.t('menu.questionnaires.EQ5D3L')}</>
                    };
                case MENU_ENTRY.GeneralFeedbackQuestionnaire:
                    return {
                        entry: MENU_ENTRY.GeneralFeedbackQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.generalFeedback')}</>
                    };
                case MENU_ENTRY.SpecificFeedbackQuestionnaire:
                    return {
                        entry: MENU_ENTRY.SpecificFeedbackQuestionnaire,
                        content: <>{i18n.t('menu.questionnaires.specificFeedback')}</>
                    };
                case MENU_ENTRY.WBC:
                    return {
                        entry: MENU_ENTRY.WBC,
                        content: <>{i18n.t('home.widget.wbc')}</>
                    };
                case MENU_ENTRY.BodyTemperature:
                    return {
                        entry: MENU_ENTRY.BodyTemperature,
                        content: <>{i18n.t('home.widget.bodyTemperature')}</>
                    };
            }
        }
        return entry;
    });
}
